<template>
    <div>
        <b-row>
            <b-col sm="4">
                <b-form-group label="Client" label-for="value">
                    <v-select
                        v-model="inheritance.client_id"
                        :options="clients"
                        label="name"
                        :reduce="(option) => option.id"
                        :disabled="disabled"
                    >
                        <template #no-options="{ }">
                            No s'han pogut carregar els clients
                        </template>
                        <template slot="option" slot-scope="option">
                            {{ option.name }} {{ option.first_surname }} {{ option.second_surname }}
                        </template>
                        <template slot="selected-option" slot-scope="option">
                            {{ option.name }} {{ option.first_surname }} {{ option.second_surname }}
                        </template>
                    </v-select>
                    <small v-if="validation_inheritance.client_id" class="text-danger">
                        {{ validation_inheritance.client_id }}
                    </small>
                </b-form-group>
            </b-col>
            <b-col lg="2" md="12" sm="12">
                <b-form-group label="Categoria" label-for="value">
                    <v-select
                        v-model="inheritance.category"
                        :options="inheritanceCategories"
                        label="name"
                        :reduce="(option) => option.value"
                    />
                    <small v-if="validation_inheritance.category" class="text-danger">
                        {{ validation_inheritance.category }}
                    </small>
                </b-form-group>
            </b-col>
            <b-col lg="4" md="12" sm="12">
                <b-form-group label="Tipus" label-for="value">
                    <v-select
                        v-model="inheritance.type"
                        :options="inheritanceTypes"
                        label="name"
                        :reduce="(option) => option.value"
                    />
                    <small v-if="validation_inheritance.type" class="text-danger">
                        {{ validation_inheritance.type }}
                    </small>
                </b-form-group>
            </b-col>
            <b-col lg="2" md="12" sm="12">
                <b-form-group label="Es necessitarà factura" label-for="value">
                    <v-select
                        v-model="inheritance.invoice"
                        :options="boolean"
                        label="name"
                        :reduce="(option) => option.value"
                    />
                    <small v-if="validation_inheritance.invoice" class="text-danger">
                        {{ validation_inheritance.invoice }}
                    </small>
                </b-form-group>
            </b-col>
        </b-row>
        <b-row>
            <b-col lg="6" md="12" sm="12">
                <b-form-group label="Autorització a GAG de representació" label-for="minuta-notari">
                    <b-form-file
                        v-model="inheritance.authorization_gag_document_file"
                        placeholder="Tria un document"
                        drop-placeholder="Tria un document"
                        browse-text="Seleccionar"
                    />
                </b-form-group>
            </b-col>
            <b-col v-if="inheritance.authorization_gag_document" class="mr-2 ml-0">
                <a :href="baseUrl+inheritance.authorization_gag_document" target="_blank">
                    <feather-icon
                        icon="EyeIcon"
                        class="cursor-pointer mt-0 mt-md-2"
                        size="25"
                    />
                </a>
                <feather-icon
                    v-if="inheritance.authorization_gag_document || inheritance.authorization_gag_document_file"
                    icon="TrashIcon"
                    class="cursor-pointer ml-1 mt-md-2"
                    size="25"
                    @click="inheritance.authorization_gag_document_file = inheritance.authorization_gag_document = null "
                />
            </b-col>
        </b-row>
        <b-row>
            <b-col lg="12" md="12" sm="12">
                <b-form-group label="Observacions internes" label-for="observations">
                    <b-form-textarea v-model="inheritance.internal_observations" placeholder="Observacions internes"
                                     :class="validation_inheritance.internal_observations ? 'is-invalid': ''"/>
                    <small v-if="validation_inheritance.internal_observations" class="text-danger">
                        {{ validation_inheritance.internal_observations }}
                    </small>
                </b-form-group>
            </b-col>
        </b-row>
        <b-row>
            <b-col lg="3" md="12" sm="12">
                <b-form-group label="Notari" label-for="document">
                    <b-form-input v-model="inheritance.notary" placeholder="Notari"/>
                </b-form-group>
            </b-col>
            <b-col sm="5">
                <b-form-group label="Minuta notari" label-for="minuta-notari">
                    <b-form-file
                        v-model="inheritance.notary_bill_document_file"
                        placeholder="Tria un document"
                        drop-placeholder="Tria un document"
                        browse-text="Seleccionar"
                    />
                </b-form-group>
            </b-col>
            <b-col v-if="inheritance.notary_bill_document" class="mr-2 ml-0">
                <a :href="baseUrl+inheritance.notary_bill_document" target="_blank">
                    <feather-icon
                        icon="EyeIcon"
                        class="cursor-pointer mt-0 mt-md-2"
                        size="25"
                    />
                </a>
                <feather-icon v-if="inheritance.notary_bill_document || inheritance.notary_bill_document_file"
                              icon="TrashIcon"
                              class="cursor-pointer ml-1 mt-md-2"
                              size="25"
                              @click="inheritance.notary_bill_document_file = inheritance.notary_bill_document = null "
                />
            </b-col>
        </b-row>
        <b-row>
            <b-col lg="3" md="12" sm="12">
                <b-form-group label="Data signatura escriptura" label-for="value">
                    <flat-pickr
                        v-model="inheritance.signature_date"
                        class="form-control"
                        :class="validation_inheritance.signature_date ? 'is-invalid': ''"
                        name="date"
                        placeholder="Data signatura escriptura"
                        :config="{ enableTime: false, locale: SpanishLocale, dateFormat: 'd-m-Y'}"
                    />
                    <small v-if="validation_inheritance.signature_date" class="text-danger">
                        {{ validation_inheritance.signature_date }}
                    </small>
                </b-form-group>
            </b-col>
            <b-col sm="5">
                <b-form-group label="Escriptura" label-for="inheritance">
                    <b-form-file
                        v-model="inheritance.inheritance_document_file"
                        placeholder="Tria un document"
                        drop-placeholder="Tria un document"
                        browse-text="Seleccionar"
                    />
                </b-form-group>
            </b-col>
            <b-col v-if="inheritance.inheritance_document" class="mr-2 ml-0">
                <a :href="baseUrl+inheritance.inheritance_document" target="_blank">
                    <feather-icon
                        icon="EyeIcon"
                        class="cursor-pointer mt-0 mt-md-2"
                        size="25"
                    />
                </a>
                <feather-icon v-if="inheritance.inheritance_document || inheritance.inheritance_document_file"
                              icon="TrashIcon"
                              class="cursor-pointer ml-1 mt-md-2"
                              size="25"
                              @click="inheritance.inheritance_document_file = inheritance.inheritance_document = null"
                />
            </b-col>
            <b-col sm="3">
                <b-form-group label="Protocol" label-for="protocol">
                    <b-form-input v-model="inheritance.protocol" placeholder="Protocol"
                                  :class="validation_inheritance.protocol ? 'is-invalid': ''"/>
                    <small v-if="validation_inheritance.protocol" class="text-danger">
                        {{ validation_inheritance.protocol }}
                    </small>
                </b-form-group>
            </b-col>
        </b-row>
        <b-row>
            <b-col lg="3" md="12" sm="12">
                <b-form-group label="Data devengo" label-for="value">
                    <flat-pickr
                        v-model="inheritance.accrual_date"
                        class="form-control"
                        :class="validation_inheritance.accrual_date ? 'is-invalid': ''"
                        name="date"
                        placeholder="Data devengo"
                        :config="{ enableTime: false, locale: SpanishLocale, dateFormat: 'd-m-Y'}"
                    />
                    <small v-if="validation_inheritance.accrual_date" class="text-danger">
                        {{ validation_inheritance.accrual_date }}
                    </small>
                </b-form-group>
            </b-col>
            <b-col lg="3" md="12" sm="12">
                <b-form-group label="Data limit" label-for="value">
                    <flat-pickr
                        v-model="inheritance.limit_date"
                        class="form-control"
                        :class="validation_inheritance.limit_date ? 'is-invalid': ''"
                        name="date"
                        placeholder="Data limit"
                        :config="{ enableTime: false, locale: SpanishLocale, dateFormat: 'd-m-Y'}"
                    />
                    <small v-if="validation_inheritance.limit_date" class="text-danger">
                        {{ validation_inheritance.limit_date }}
                    </small>
                </b-form-group>
            </b-col>
            <b-col lg="3" md="12" sm="12">
                <b-form-group label="Data prescripció" label-for="prescripció">
                    <flat-pickr
                        v-model="inheritance.prescription_date"
                        class="form-control"
                        :class="validation_inheritance.prescription_date ? 'is-invalid': ''"
                        name="date"
                        placeholder="Data prescripció"
                        :config="{ enableTime: false, locale: SpanishLocale, dateFormat: 'd-m-Y'}"
                    />
                    <small v-if="validation_inheritance.prescription_date" class="text-danger">
                        {{ validation_inheritance.prescription_date }}
                    </small>
                </b-form-group>
            </b-col>
            <b-col lg="3" md="12" sm="12">
                <b-form-group label="Estat" label-for="value">
                    <select v-model="inheritance.status" class="form-group custom-select"
                            :class="'background-' + inheritance.status">-
                        <option v-for="status in scripturesStatus"
                                :key="status.value"
                                :selected="status.value === inheritance.status"
                                :value="status.value"> {{ status.name }}
                        </option>
                    </select>
                    <small v-if="validation_inheritance.status" class="text-danger">
                        {{ validation_inheritance.status }}
                    </small>
                </b-form-group>
            </b-col>
        </b-row>
        <hr>
        <b-row>
            <b-col lg="3" md="12" sm="12">
                <b-form-group label="Data de defunció" label-for="value">
                    <flat-pickr
                        v-model="inheritance.date_of_death"
                        class="form-control"
                        :class="validation_inheritance.date_of_death ? 'is-invalid': ''"
                        name="date"
                        placeholder="Data signatura escriptura"
                        :config="{ enableTime: false, locale: SpanishLocale, dateFormat: 'd-m-Y'}"
                    />
                    <small v-if="validation_inheritance.date_of_death" class="text-danger">
                        {{ validation_inheritance.date_of_death }}
                    </small>
                </b-form-group>
            </b-col>

            <b-col sm="4">
                <b-form-group label="On residia" label-for="protocol">
                    <b-form-input v-model="inheritance.resided_at" placeholder="On residia"
                                  :class="validation_inheritance.resided_at ? 'is-invalid': ''"/>
                    <small v-if="validation_inheritance.resided_at" class="text-danger">
                        {{ validation_inheritance.resided_at }}
                    </small>
                </b-form-group>
            </b-col>
            <b-col class="col-sm-4">
                <b-form-group label="Comunidad autonoma" label-for="value">
                    <v-select
                        v-model="inheritance.autonomous_community"
                        :options="autonomousCommunities"
                        label="name"
                        :reduce="(option) => option.value"
                    />
                    <small v-if="validation_inheritance.autonomous_community" class="text-danger">
                        {{ validation_inheritance.autonomous_community }}
                    </small>
                </b-form-group>
            </b-col>
        </b-row>
        <b-row class="col-sm-12">
            <b-col class="alert alert-primary p-1" role="alert">
                <span>En caso de <strong>NO</strong> haber testamento y residir a fecha fallecimiento fuera de Vilanova Dec. herederos en notaria del domicilio del causante.</span>
            </b-col>
        </b-row>
        <b-row>
            <b-col sm="4">
                <b-form-group label="Sol·licitud prorroga" label-for="prorroga">
                    <div class="demo-left-spacing">
                        <b-form-radio
                            v-for="bool in boolean"
                            :key="bool.name"
                            v-model="inheritance.extension_request"
                            name="prorroga"
                            class="mt-1"
                            :value="bool.value"
                        >
                            {{ bool.name }}
                        </b-form-radio>
                    </div>
                </b-form-group>
            </b-col>
            <b-col sm="4">
                <b-form-group class="pt-2" label="" label-for="extension_type">
                    <div class="demo-left-spacing">
                        <b-form-checkbox
                            v-model="inheritance.townhall"
                            name="townhall"
                            class="mt-1"
                            unchecked-value="0"
                            :value="1"
                        >
                            Ajuntament
                        </b-form-checkbox>
                        <b-form-checkbox
                            v-model="inheritance.isd"
                            name="isd"
                            class="mt-1"
                            unchecked-value="0"
                            :value="1"
                        >
                            ISD
                        </b-form-checkbox>
                    </div>
                </b-form-group>
            </b-col>
        </b-row>
        <capital-gains-form/>
        <div>
            <b-card title="Liquidació" border-variant="primary" class="text-left mt-2">
                <b-row>
                    <b-col sm="4">
                        <b-form-group label="Document" label-for="document">
                            <b-form-file
                                v-model="inheritance.liquidation_document_file"
                                placeholder="Tria un document"
                                drop-placeholder="Tria un document"
                                browse-text="Seleccionar"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col v-if="inheritance.liquidation_document" class="mr-2 ml-0">
                        <a :href="baseUrl+inheritance.liquidation_document" target="_blank">
                            <feather-icon
                                icon="EyeIcon"
                                class="cursor-pointer mt-0 mt-md-2"
                                size="25"
                            />
                        </a>
                        <feather-icon v-if="inheritance.liquidation_document || inheritance.liquidation_document_file"
                                      icon="TrashIcon"
                                      class="cursor-pointer ml-1 mt-md-2"
                                      size="25"
                                      @click="inheritance.liquidation_document_file = inheritance.liquidation_document = null "
                        />
                    </b-col>
                </b-row>
                <model660-form/>
                <model650-form/>
                <model652-form/>
                <model653-form/>
                <openModel-form/>
            </b-card>

            <b-card title="Registres" border-variant="primary" class="text-left mt-2">
                <b-col lg="12" md="12" sm="12">
                    <b-row v-for="(property_record, index) in inheritance.properties"
                           :key="property_record.id">
                        <b-col sm="3">
                            <b-form-group label="Descripció" label-for="document">
                                <b-form-input v-model="property_record.description" placeholder="Descripció"/>
                            </b-form-group>
                        </b-col>
                        <b-col sm="2">
                            <b-form-group label="Document" label-for="document">
                                <b-form-file
                                    v-model="property_record.document_file"
                                    placeholder="Seleccionar"
                                    drop-placeholder="Seleccionar"
                                    browse-text="Seleccionar"
                                />
                            </b-form-group>
                        </b-col>
                        <b-col v-if="property_record.document" class="mr-2 ml-0">
                            <a :href="baseUrl+property_record.document" target="_blank">
                                <feather-icon
                                    icon="EyeIcon"
                                    class="cursor-pointer mt-0 mt-md-2"
                                    size="25"
                                />
                            </a>
                            <feather-icon v-if="property_record.document || property_record.document_file"
                                          icon="TrashIcon"
                                          class="cursor-pointer ml-1 mt-md-2"
                                          size="25"
                                          @click="property_record.document_file = property_record.document = null "
                            />
                        </b-col>
                        <b-col sm="2">
                            <b-form-group label="Data entrada" label-for="date-liquidation">
                                <flat-pickr
                                    v-model="property_record.date_entry"
                                    class="form-control"
                                    name="date"
                                    placeholder="Data entrada"
                                    :config="{ enableTime: false, locale: SpanishLocale, dateFormat: 'd-m-Y'}"
                                />
                            </b-form-group>
                        </b-col>
                        <b-col sm="2">
                            <b-form-group label="Data sortida" label-for="date-liquidation">
                                <flat-pickr
                                    v-model="property_record.date_exit"
                                    class="form-control"
                                    name="date"
                                    placeholder="Data sortida"
                                    :config="{ enableTime: false, locale: SpanishLocale, dateFormat: 'd-m-Y'}"
                                />
                            </b-form-group>
                        </b-col>
                        <b-col sm="1">
                            <b-button
                                variant="outline-danger"
                                class="mt-0 mt-md-2"
                                @click="removePropertyItem(index, property_record.id)"
                            >
                                <feather-icon
                                    icon="TrashIcon"
                                    class="mr-25"
                                />
                            </b-button>
                        </b-col>
                        <b-col sm="12">
                            <b-form-group label="Observacions" label-for="date-liquidation">
                                <b-form-textarea v-model="property_record.observations" placeholder="Observacions"/>
                            </b-form-group>
                        </b-col>
                        <hr/>
                    </b-row>

                    <b-row>
                        <b-col class="d-flex align-items-center justify-content-start" cols="12" md="12">
                            <b-button @click="addNewPropertyRow()">
                                <feather-icon
                                    icon="PlusIcon"
                                    class="mr-25"
                                />
                                <span>Afegir registre</span>
                            </b-button>
                        </b-col>
                    </b-row>
                </b-col>
            </b-card>
        </div>
        <b-row>
            <b-col>
                <b-form-group label="" label-for="separation">
                    <b-form-checkbox
                        id="new_construction"
                        v-model="inheritance.separation"
                        name="new_construction"
                        unchecked-value="0"
                        :value="1"
                    >
                        Si separació
                    </b-form-checkbox>
                </b-form-group>
            </b-col>
            <b-col>
                <b-form-group label="" label-for="separation">
                    <b-form-checkbox
                        id="separation"
                        v-model="inheritance.segregation"
                        name="separation"
                        unchecked-value="0"
                        :value="1"
                    >
                        Segregació
                    </b-form-checkbox>
                </b-form-group>
            </b-col>
            <b-col>
                <b-form-group label="" label-for="division">
                    <b-form-checkbox
                        id="division"
                        v-model="inheritance.division"
                        name="division"
                        unchecked-value="0"
                        :value="1"
                    >
                        Divisió
                    </b-form-checkbox>
                </b-form-group>
            </b-col>
        </b-row>
        <b-row>
            <b-col class="col-sm-3">
                <b-form-group label="Modificació catastral" label-for="value">
                    <v-select
                        v-model="inheritance.modification_register"
                        :options="boolean"
                        label="name"
                        :reduce="(option) => option.value"
                    />
                    <small v-if="validation_inheritance.modification_register" class="text-danger">
                        {{ validation_inheritance.modification_register }}
                    </small>
                </b-form-group>
            </b-col>
            <b-col class="col-sm-3">
                <b-form-group label="Comprovació valors catastrals" label-for="value">
                    <v-select
                        v-model="inheritance.comprobation_value_register"
                        :options="boolean"
                        label="name"
                        :reduce="(option) => option.value"
                    />
                    <small v-if="validation_inheritance.comprobation_value_register" class="text-danger">
                        {{ validation_inheritance.comprobation_value_register }}
                    </small>
                </b-form-group>
            </b-col>

        </b-row>
        <div  v-if="inheritance.modification_register === 1">
            <model902-form/>
        </div>
        <b-row>
            <b-col sm="3">
                <b-form-group label="Valor de referencia" label-for="document">
                    <money class="form-control" v-model="inheritance.minimum_value" v-bind="money"/>
                    <small v-if="validation_inheritance.minimum_value" class="text-danger">
                        {{ validation_inheritance.minimum_value }}
                    </small>
                </b-form-group>
            </b-col>
            <b-col sm="4">
                <b-form-group label="Document" label-for="document">
                    <b-form-file
                        v-model="inheritance.reference_value_document_file"
                        placeholder="Tria un document"
                        drop-placeholder="Tria un document"
                        browse-text="Seleccionar"
                    />
                </b-form-group>
            </b-col>
            <b-col v-if="inheritance.reference_value_document" class="mr-2 ml-0">
                <a :href="baseUrl+inheritance.reference_value_document" target="_blank">
                    <feather-icon
                        icon="EyeIcon"
                        class="cursor-pointer mt-0 mt-md-2"
                        size="25"
                    />
                </a>
                <feather-icon v-if="inheritance.reference_value_document || inheritance.reference_value_document_file"
                              icon="TrashIcon"
                              class="cursor-pointer ml-1 mt-md-2"
                              size="25"
                              @click="inheritance.reference_value_document_file = inheritance.reference_value_document = null "
                />
            </b-col>
        </b-row>
        <b-row>
            <b-col sm="3">
                <b-form-group label="Valor catastral" label-for="document">
                    <money class="form-control" v-model="inheritance.register_value" v-bind="money"/>
                    <small v-if="validation_inheritance.register_value" class="text-danger">
                        {{ validation_inheritance.register_value }}
                    </small>
                </b-form-group>
            </b-col>
            <b-col sm="4">
                <b-form-group label="Document" label-for="document">
                    <b-form-file
                        v-model="inheritance.register_value_document_file"
                        placeholder="Tria un document"
                        drop-placeholder="Tria un document"
                        browse-text="Seleccionar"
                    />
                </b-form-group>
            </b-col>
            <b-col v-if="inheritance.register_value_document" class="mr-2 ml-0">
                <a :href="baseUrl+inheritance.register_value_document" target="_blank">
                    <feather-icon
                        icon="EyeIcon"
                        class="cursor-pointer mt-0 mt-md-2"
                        size="25"
                    />
                </a>
                <feather-icon v-if="inheritance.register_value_document || inheritance.register_value_document_file"
                              icon="TrashIcon"
                              class="cursor-pointer ml-1 mt-md-2"
                              size="25"
                              @click="inheritance.register_value_document_file = inheritance.register_value_document = null "
                />
            </b-col>
        </b-row>
        <b-row>
            <b-col sm="3">
                <b-form-group label="Coeficient" label-for="document">
                    <b-form-input v-model="inheritance.coefficient_value" placeholder="Coeficient"/>
                </b-form-group>
            </b-col>
            <b-col sm="4">
                <b-form-group label="Document" label-for="document">
                    <b-form-file
                        v-model="inheritance.coefficient_value_document_file"
                        placeholder="Tria un document"
                        drop-placeholder="Tria un document"
                        browse-text="Seleccionar"
                    />
                </b-form-group>
            </b-col>
            <b-col v-if="inheritance.coefficient_value_document" class="mr-2 ml-0">
                <a :href="baseUrl+inheritance.coefficient_value_document" target="_blank">
                    <feather-icon
                        icon="EyeIcon"
                        class="cursor-pointer mt-0 mt-md-2"
                        size="25"
                    />
                </a>
                <feather-icon
                    v-if="inheritance.coefficient_value_document || inheritance.coefficient_value_document_file"
                    icon="TrashIcon"
                    class="cursor-pointer ml-1 mt-md-2"
                    size="25"
                    @click="inheritance.coefficient_value_document_file = inheritance.coefficient_value_document = null "
                />
            </b-col>
        </b-row>
        <b-row>
            <b-col sm="3">
                <b-form-group label="Valor minim" label-for="document">
                    <money class="form-control" v-model="inheritance.minimum_value" v-bind="money"/>
                    <small v-if="validation_inheritance.minimum_value" class="text-danger">
                        {{ validation_inheritance.minimum_value }}
                    </small>
                </b-form-group>
            </b-col>
            <b-col sm="4">
                <b-form-group label="Document" label-for="document">
                    <b-form-file
                        v-model="inheritance.minimum_value_document_file"
                        placeholder="Tria un document"
                        drop-placeholder="Tria un document"
                        browse-text="Seleccionar"
                    />
                </b-form-group>
            </b-col>
            <b-col v-if="inheritance.minimum_value_document" class="mr-2 ml-0">
                <a :href="baseUrl+inheritance.minimum_value_document" target="_blank">
                    <feather-icon
                        icon="EyeIcon"
                        class="cursor-pointer mt-0 mt-md-2"
                        size="25"
                    />
                </a>
                <feather-icon v-if="inheritance.minimum_value_document || inheritance.minimum_value_document_file"
                              icon="TrashIcon"
                              class="cursor-pointer ml-1 mt-md-2"
                              size="25"
                              @click="inheritance.minimum_value_document_file = inheritance.minimum_value_document = null "
                />
            </b-col>
        </b-row>
        <b-row>
            <b-col lg="12" md="12" sm="12">
                <b-form-group label="Observacions" label-for="observations">
                    <b-form-textarea v-model="inheritance.observations" placeholder="Observacions"
                                     :class="validation_inheritance.observations ? 'is-invalid': ''"/>
                    <small v-if="validation_inheritance.observations" class="text-danger">
                        {{ validation_inheritance.observations }}
                    </small>
                </b-form-group>
            </b-col>
        </b-row>
        <invoices-form/>
    </div>
</template>

<script>
import {
    BCol,
    BFormGroup,
    BFormInput,
    BRow,
    BFormRadio,
    BFormFile,
    BFormTextarea,
    BButton,
    BCard,
    BFormCheckbox,
    BFormRadioGroup,
} from 'bootstrap-vue'
import {Spanish as SpanishLocale} from 'flatpickr/dist/l10n/es'
import vSelect from "vue-select"
import flatPickr from 'vue-flatpickr-component'
import {createHelpers} from "vuex-map-fields"
import {mapState} from "vuex"
import {
    inheritanceCategories,
    inheritanceTypes,
    autonomousCommunities,
    scripturesTypes, scripturesStatus
} from "@/constants/trading"
import {baseUrl} from "@/constants/app"
import model650Form from "@/views/clients/form/components/models/model650Form"
import model660Form from "@/views/clients/form/components/models/model660Form"
import model652Form from "@/views/clients/form/components/models/model652Form"
import model653Form from "@/views/clients/form/components/models/model653Form"
import model902Form from "@/views/clients/form/components/models/model902Form"
import openModelForm from "@/views/clients/form/components/models/modelOpenModelForm"
import capitalGainsForm from "@/views/clients/form/components/models/capitalGainForm"
import invoicesForm from "@/views/trading/inheritances/components/invoicesForm"
import {boolean} from "@/constants/constants"
import Swal from "sweetalert2"
import helpers from "@/resources/helpers/helpers"

const {mapFields} = createHelpers({
    getterType: 'inheritances/getField',
    mutationType: 'inheritances/updateField',
})

export default {
    name: "FormInheritancesView",
    components: {
        BCol,
        BRow,
        BFormGroup,
        BFormInput,
        BFormRadio,
        BFormFile,
        BFormTextarea,
        BButton,
        BCard,
        BFormCheckbox,
        BFormRadioGroup,

        model650Form,
        model660Form,
        model652Form,
        model653Form,
        model902Form,
        invoicesForm,
        openModelForm,
        capitalGainsForm,
        vSelect,
        flatPickr,
    },
    props: ['disabled'],
    data() {
        return {
            SpanishLocale,
            clients: [],
            documentsTypesList: [],

            inheritanceTypes,
            inheritanceCategories,
            scripturesTypes,
            autonomousCommunities,
            scripturesStatus,

            boolean,
            baseUrl,
            helpers
        }
    },
    computed: {
        ...mapState('inheritances', ['validation_inheritance']),
        ...mapFields({
            inheritance: 'inheritance',
        }),
    },
    watch: {
        'inheritance.signature_date': function (changes) {
            if (changes) {
                const partsDate = changes.split('-')
                const date = new Date(partsDate[2], partsDate[1] - 1, partsDate[0])
                date.setMonth(date.getMonth() + 1)
                this.inheritance.limit_date = `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`
                this.inheritance.accrual_date = changes
            }
        },
        'inheritance.accrual_date': function (changes) {
            if (changes) {
                this.inheritance.date_of_death = changes
            }
        },
        'inheritance.limit_date': function (changes) {
            if (changes) {
                const partsDate = changes.split('-')
                const date = new Date(partsDate[2], partsDate[1] - 1, partsDate[0])
                date.setFullYear(date.getFullYear() + 4)
                this.inheritance.prescription_date = `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`
            }
        },
        'inheritance.date_of_death': function (changes) {
            if (changes) {
                const partsDate = changes.split('-')
                const date = new Date(partsDate[2], partsDate[1] - 1, partsDate[0])
                date.setMonth(date.getMonth() + 6)
                this.inheritance.limit_date = `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`
            }
        },
        'inheritance.client_id': function (client) {
            if (client) {
                this.getClientData(client)
            }
        },
    },
    mounted() {
        this.getClients()
    },
    methods: {
        getClients() {
            this.showLoading = true

            this.$store.dispatch('client/getAllClients', {
                id: this.$route.params.id,
                relations: ['responsible'],
            })
            .then(response => {
                this.clients = response
                this.showLoading = false
            })
            .catch(error => {
                this.showLoading = false
            })
        },

        addNewPropertyRow() {
            this.inheritance.properties.push({
                description: null,
                document: null,
                document_file: null,
                date_entry: null,
                date_exit: null,
                observations: null,
            })
        },
        removePropertyItem(index, propertyId) {

            if (propertyId) {
                Swal.fire({
                    title: "Esteu segur d'esborrar el registre?",
                    text: "Un cop esborrat no ho podràs recuperar!",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#34c38f",
                    cancelButtonColor: "#f46a6a",
                    confirmButtonText: "Sí, esborra'l!",
                    cancelButtonText: "Cancel·lar",
                }).then(result => {
                    if (result.value) {
                        this.$store.dispatch('inheritances/deleteInheritanceProperty', propertyId)
                        .then(response => {
                            this.showLoading = false
                            helpers.showToast('Document eliminado correctament', 'CheckIcon', 'success')
                            this.inheritance.properties.splice(index, 1)
                        })
                        .catch(error => {
                            this.showLoading = false
                            helpers.showToast('Error en eliminar document', 'CheckIcon', 'Alert')
                        })
                    }
                })
            } else {
                this.inheritance.properties.splice(index, 1)
            }
        },
        addNewRowModel(modelFlag, flag) {
            modelFlag.push({
                liquidation_date: null,
                signature_date: null,
                flag: flag,
            })
        },
        removeItemModel(modelFlag, index, modelDocumentId) {
            if (modelDocumentId) {
                Swal.fire({
                    title: "Esteu segur d'esborrar el registre?",
                    text: "Un cop esborrat no ho podràs recuperar!",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#34c38f",
                    cancelButtonColor: "#f46a6a",
                    confirmButtonText: "Sí, esborra'l!",
                    cancelButtonText: "Cancel·lar",
                }).then(result => {
                    if (result.value) {
                        this.$store.dispatch('inheritances/deleteInheritanceModelRegister', modelDocumentId)
                        .then(response => {
                            this.showLoading = false
                            helpers.showToast('Document eliminado correctament', 'CheckIcon', 'success')
                            modelFlag.splice(index, 1)
                        })
                        .catch(error => {
                            this.showLoading = false
                            helpers.showToast('Error en eliminar document', 'CheckIcon', 'Alert')
                        })
                    }
                })
            } else {
                modelFlag.splice(index, 1)
            }
        },
        getClientData(client_id) {
            this.showLoading = true
            this.$store.dispatch('client/getClient', {
                id: client_id,
                relations: ['clientModels'],
            })
            .then(response => {
                this.showLoading = false
            })
            .catch(error => {
                this.showLoading = false
            })
        },
    }
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

@import '@core/scss/vue/libs/vue-flatpicker.scss';

hr {
    display: block;
    width: 100%;
    height: 1px;
}

.background-asked_signed {
    color: #ff07caf7;
    border: 1px solid #ff07caf7;
}

.background-signed {
    color: #ffd507;
    border: 1px solid #ffd507;
}

.background-taxes_liquidation {
    color: #ff9f43;
    border: 1px solid #ff9f43;
}

.background-register {
    color: #c843fff5;
    border: 1px solid #c843fff5;
}

.background-finished {
    color: #28c76f;
    border: 1px solid #28c76f;
}

select option[value="asked_signed"] {
    color: #ff07caf7 !important;
    background: #ff07ca40;
}

select option[value="signed"] {
    color: #ffc107 !important;
    background: #f3ff438f
}

select option[value="taxes_liquidation"] {
    color: #ff9f43 !important;
    background: #ff9f434d
}

select option[value="register"] {
    color: #c843fff5 !important;
    background: #D143FF4D
}

select option[value="finished"] {
    color: #28c76f !important;
    background: rgba(40, 199, 111, 0.12);
}
</style>