<template>
    <div>
        <b-card class="p-1 p-lg-0">
            <b-tabs align="left">
                <b-tab active title="Informació general" lazy>
                    <b-overlay
                        variant="white"
                        :show="showLoading"
                        spinner-variant="primary"
                        blur="0"
                        opacity=".75"
                        rounded="sm"
                    >
                        <form-inheritance :disabled="edit"/>

                        <b-row align-h="start">
                            <b-col cols="12" class="d-flex align-items-center justify-content-end">
                                <b-button v-if="!edit" variant="primary" class="mt-2 mr-1" @click="sendData">
                                    Desar canvis
                                </b-button>
                                <b-button v-else variant="primary" class="mt-2 mr-1" @click="updateData">
                                    Actualitzar canvis
                                </b-button>
                                <b-button variant="outline-secondary" type="reset" class="mt-2" @click="cancel">
                                    Cancel·lar
                                </b-button>
                            </b-col>
                        </b-row>
                    </b-overlay>
                </b-tab>
                <b-tab v-if="inheritance.id" title="Dades personals" lazy>
                    <form-data-address-client :disabled="true"/>
                </b-tab>
                <b-tab v-if="inheritance.id" title="Documents" lazy>
                    <form-documents :disabled="true"/>
                    <b-row align-h="start">
                        <b-col cols="12" class="d-flex align-items-center justify-content-end">
                            <b-button v-if="!edit" variant="primary" class="mt-2 mr-1" @click="sendData">
                                Desar canvis
                            </b-button>
                            <b-button v-else variant="primary" class="mt-2 mr-1" @click="updateData">
                                Actualitzar canvis
                            </b-button>
                            <b-button variant="outline-secondary" type="reset" class="mt-2" @click="cancel">
                                Cancel·lar
                            </b-button>
                        </b-col>
                    </b-row>
                </b-tab>
            </b-tabs>

        </b-card>

    </div>
</template>

<script>
import {BButton, BCard, BCol, BTab, BTabs, BOverlay, BRow} from 'bootstrap-vue'
import {Spanish as SpanishLocale} from 'flatpickr/dist/l10n/es'
import {INHERITANCES_LIST} from "@/router/web/constants"
import ToastificationContent from "@core/components/toastification/ToastificationContent"

import FormInheritance from "@/views/trading/inheritances/form/formInheritanceView"
import FormDataAddressClient from "@/views/clients/form/formClientPersonalDataView"
import FormDocuments from "@/views/trading/inheritances/components/formDocumentsView"

import {createHelpers} from "vuex-map-fields"
import {mapState} from "vuex"

const {mapFields} = createHelpers({
    getterType: 'inheritances/getField',
    mutationType: 'inheritances/updateField',
})

export default {
    name: "inheritanceFormView",
    components: {
        BCol,
        BRow,
        BButton,
        BCard,
        BOverlay,
        BTab,
        BTabs,

        FormInheritance,
        FormDataAddressClient,
        FormDocuments,
    },
    data() {
        return {
            users: [],

            client: null,
            edit: false,
            showLoading: false,
            SpanishLocale,
        }
    },
    computed: {
        ...mapState('inheritances', ['validation_inheritance']),
        ...mapFields({
            inheritance: 'inheritance',
        }),
    },
    created() {
        this.$store.commit('inheritances/RESET_STATE')

        if (this.$route.params.id) {
            this.edit = true
            this.getInheritance()
        } else {
            this.edit = false
        }
    },
    methods: {
        getInheritance() {
            this.showLoading = true

            this.$store.dispatch('inheritances/getInheritance', {
                id: this.$route.params.id ?? this.inheritance.id,
                relations: ['client', 'client.clientPersonalData','documents', 'client.clientModels', 'properties', 'invoices'],
            })
            .then(response => {
                this.inheritance.inheritance_documents = response.documents
                this.showLoading = false
            })
            .catch(error => {
                this.showLoading = false
            })
        },
        sendData() {
            this.$store.dispatch('inheritances/createInheritance', this.formatData())
            .then(response => {
                this.edit = true

                this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                        title: `Tràmit d'herència creat correctament!`,
                        icon: 'CheckIcon',
                        variant: 'success',
                    },
                })
                this.inheritance.id = response.id
                this.getInheritance()
            })
            .catch(error => {
                console.log(error)
            })
        },
        updateData() {
            this.$store.dispatch('inheritances/updateInheritance', this.formatData())
            .then(() => {
                this.showLoading = false
                this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                        title: `Tràmit d'herència editat correctament!`,
                        icon: 'CheckIcon',
                        variant: 'success',
                    },
                })
                this.getInheritance()
            })
            .catch(error => {
                console.log(error)
            })
        },
        cancel() {
            this.$router.push(INHERITANCES_LIST)
        },
        formatData() {
            var formData = new FormData()
            this.appendFormData(formData, this.inheritance)
            this.appendFormData(formData, this.$store.state.client.client.capital_gains, 'capital_gains')
            this.appendFormData(formData, this.$store.state.client.client.models_records_660, 'models_records_660')
            this.appendFormData(formData, this.$store.state.client.client.models_records_650, 'models_records_650')
            this.appendFormData(formData, this.$store.state.client.client.models_records_652, 'models_records_652')
            this.appendFormData(formData, this.$store.state.client.client.models_records_653, 'models_records_653')
            this.appendFormData(formData, this.$store.state.client.client.models_records_open_models, 'models_records_open_models')
            return formData
        },
        appendFormData(formData, data, rootName) {
            let root = rootName || ''
            if (data instanceof File) {
                formData.append(root, data)
            } else if (Array.isArray(data)) {
                for (var i = 0; i < data.length; i++) {
                    this.appendFormData(formData, data[i], root + '[' + i + ']')
                }
            } else if (typeof data === 'object' && data) {
                for (var key in data) {
                    if (data.hasOwnProperty(key)) {
                        if (root === '') {
                            this.appendFormData(formData, data[key], key)
                        } else {
                            this.appendFormData(formData, data[key], `${root}[${key}]`)
                        }
                    }
                }
            } else {
                if (data !== null && typeof data !== 'undefined') {
                    formData.append(root, data)
                } else {
                    formData.append(root, '')
                }
            }
        },
    },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>