<template>

    <b-card title="Factures" border-variant="primary" class="text-left mt-2">
        <b-col lg="12" md="12" sm="12">
            <b-row v-for="(invoice, index) in inheritance.invoices"
                   :key="invoice.id">
                <b-col sm="4">
                    <b-form-group label="Document" label-for="document">
                        <b-form-file
                            v-model="invoice.document_file"
                            placeholder="Tria un document"
                            drop-placeholder="Tria un document"
                            browse-text="Seleccionar"
                        />
                    </b-form-group>
                </b-col>

                <b-col v-if="invoice.document" lg="1" md="12" sm="12">
                    <a :href="baseUrl+invoice.document" target="_blank">
                        <feather-icon
                            icon="EyeIcon"
                            class="cursor-pointer mt-0 mt-md-2"
                            size="25"
                        />
                    </a>
                </b-col>
                <b-col sm="4">
                    <b-form-group label="Descripció" label-for="document">
                        <b-form-input v-model="invoice.description"
                                      placeholder="Descripció"/>
                    </b-form-group>
                </b-col>
                <b-col lg="3" md="12" sm="12" class="px-3 mt-2">
                    <b-button
                        variant="outline-danger"
                        class="md-1"
                        @click="removeItemInvoice(index, invoice.id)"
                    >
                        <feather-icon
                            icon="XIcon"
                            class="mr-25"
                        />
                        <span>Eliminar Factura</span>
                    </b-button>
                </b-col>
            </b-row>
            <b-row>
                <b-col class="d-flex align-items-center justify-content-start" cols="12" md="12">
                    <b-button @click="addNewRowInvoice()">
                        <feather-icon
                            icon="PlusIcon"
                            class="mr-25"
                        />
                        <span>Afegir Factura</span>
                    </b-button>
                </b-col>
            </b-row>
        </b-col>
    </b-card>
</template>
<script>
import {
    BCol,
    BFormGroup,
    BFormInput,
    BRow,
    BFormRadio,
    BFormFile,
    BFormTextarea,
    BButton,
    BCard,
    BFormCheckbox
} from 'bootstrap-vue'
import {Spanish as SpanishLocale} from 'flatpickr/dist/l10n/es'
import vSelect from "vue-select"
import flatPickr from 'vue-flatpickr-component'
import {createHelpers} from "vuex-map-fields"
import {mapState} from "vuex"
import {baseUrl} from "@/constants/app"
import helpers from "@/resources/helpers/helpers"
import Swal from "sweetalert2"

const {mapFields} = createHelpers({
    getterType: 'inheritances/getField',
    mutationType: 'inheritances/updateField',
})

export default {
    name: "Invoices",
    components: {
        BCol,
        BRow,
        BFormGroup,
        BFormInput,
        BFormRadio,
        BFormFile,
        BFormTextarea,
        BButton,
        BCard,
        BFormCheckbox,

        vSelect,
        flatPickr,
    },
    data() {
        return {
            SpanishLocale,
            clients: [],
            baseUrl,
            helpers,
        }
    },
    computed: {
        ...mapState('inheritances', ['validation_inheritance']),
        ...mapFields({
            inheritance: 'inheritance',
        }),
    },
    methods: {
        addNewRowInvoice() {
            this.inheritance.invoices.push({
                description: null,
                document: null,
                document_file: null,
            })
        },
        removeItemInvoice(index, invoiceId) {
            if (invoiceId) {
                Swal.fire({
                    title: "Esteu segur d'esborrar el registre?",
                    text: "Un cop esborrat no ho podràs recuperar!",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#34c38f",
                    cancelButtonColor: "#f46a6a",
                    confirmButtonText: "Sí, esborra'l!",
                    cancelButtonText: "Cancel·lar",
                }).then(result => {
                    if (result.value) {
                        this.$store.dispatch('inheritances/deleteInheritanceInvoice', invoiceId)
                        .then(response => {
                            this.showLoading = false
                            helpers.showToast('Document eliminado correctament', 'CheckIcon', 'success')
                            this.inheritance.invoices.splice(index, 1)
                        })
                        .catch(error => {
                            this.showLoading = false
                            helpers.showToast('Error en eliminar document', 'CheckIcon', 'Alert')
                        })
                    }
                })
            } else {
                this.inheritance.invoices.splice(index, 1)
            }
        },
    }
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>